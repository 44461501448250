import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { asia71178_bband } from '~/trades/indicators/asia71178/asia71178_bband'
import { asia71178_gwa } from '~/trades/indicators/asia71178/asia71178_gwa'
import { asia71178_stochastic } from '~/trades/indicators/asia71178/asia71178_stochastic'
import { asia71178_kd77 } from '~/trades/indicators/asia71178/asia71178_kd77'
import { asia71178_macd77 } from '~/trades/indicators/asia71178/asia71178_macd77'
import { asia71178_score } from '~/trades/indicators/asia71178/asia71178_score'
import { asia71178_wma } from '~/trades/indicators/asia71178/asia71178_wma'
import { asia71178_circle } from '~/trades/indicators/asia71178/asia71178_circle'

export const asia71178_indicators = [
  //asia71178_bband,
  //asia71178_wma,
  //asia71178_stochastic,
  // asia71178_kd77,
  //asia71178_macd77,
  // --------------- 本揚哥提供的指標 ---------------
  asia71178_gwa,
  asia71178_circle,
  //asia71178_score,
]

export const asia71178_strategiesGroup = {
  main: [
    {
      displayName: '策略',
      indicators: asia71178_indicators,
      symbol: 'TX-1',
      interval: 'D',
      panesRatio: 80,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const asia71178_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...asia71178_indicators,
  ]

  store.charting.initStrategy({
    configs: [...asia71178_strategiesGroup.main],
  })
}
