import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { intersection } from 'lodash'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { AppLink2 } from '~/components/AppLink2'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import {
  useExtremeVolumeResource,
  usePercentRangeResource,
} from '~/modules/screener/containers/useStockScreenerResource'
import { useDatePick } from '~/modules/screener/useDatePick'
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase'
import { asia71178_store } from './asia71178_store'

const watchListGroup = 'asia71178_group_1'

export const Asia71178_SidePane2 = memo<ReactProps>(function Asia71178_SidePane2() {
  //選股
  const date = useDatePick()
  const dailyRankData = usePercentRangeResource({
    days: 5,
    status: 'new_high',
    volume_gte: 5000,
    volume_lte: 0,
  })

  const extremeVolumeData = useExtremeVolumeResource({
    date: date,
    days: 5,
    volume_gte: 10000,
    volume_lte: 0,
    threshold: 1.25,
  })

  console.log(
    'dailyRankData',
    dailyRankData.data?.length,
    'extremeVolumeData',
    extremeVolumeData.data?.length,
  )

  const dailyRankSymbol = dailyRankData.data?.map(datum => datum.symbol)
  const extremeVolumeSymbol = extremeVolumeData.data
    ?.filter(a => a.volume / a.volume_ma < 1.25 * 2)
    ?.map(datum => datum.symbol)

  const allSymbol = intersection(dailyRankSymbol, extremeVolumeSymbol)

  return (
    <styleds.container>
      <styleds.pageContent>
        <PageSwitch
          url='/asia71178'
          keys='default'
        >
          技術分析
        </PageSwitch>
        <PageSwitch
          url='/asia71178/option'
          keys='option'
        >
          選擇權
        </PageSwitch>
      </styleds.pageContent>

      <styleds.componentCard height={'calc(100% - 48px)'}>
        <SymbolPlatformBase.Display
          symbol={allSymbol}
          watchListGroup={watchListGroup}
          listTypeSwitch={false}
        />
      </styleds.componentCard>
    </styleds.container>
  )
})

const PageSwitch = memo<ReactProps<{ url: string; keys: 'default' | 'option' }>>(
  function PageSwitch(props) {
    const state = useSnapshot(asia71178_store).pageState
    const currentUrl = window.location.href
    const selectedPage = state === props.keys || currentUrl.includes(props.keys)

    return (
      <styleds.switchButton
        selected={selectedPage}
        onClick={() => (asia71178_store.pageState = props.keys)}
      >
        <AppLink2
          href={props.url}
          css={css`
            color: ${selectedPage ? '#252525' : '#aaaaaa'};
          `}
        >
          {props.children}
          {selectedPage}
        </AppLink2>
      </styleds.switchButton>
    )
  },
)

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
  `,
  componentCard: styled.div<{ height: string }>`
    width: 100%;
    height: ${props => props.height};
    padding: 4px;
    border-radius: 6px;
  `,
  pageContent: styled.div`
    ${fill_horizontal_all_center};
    height: 40px;
    gap: 4px;
  `,
  switchButton: styled.div<{ selected: boolean }>`
    ${fill_horizontal_all_center};
    width: 100%;
    height: 32px;
    background-color: #fafafa;
    border-radius: 4px;
    cursor: pointer;
    color: #252525;
    border: 1px solid ${props => (props.selected ? '#222222' : '#cacaca')};
    &:hover {
      background-color: #f0f0f0;
    }
    user-select: none;
  `,
}
