import { css } from '@emotion/react'
import { SegmentedControl } from '@mantine/core'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { getOptionIntradayEndDate } from '~/pages/daddy960_opkevin/component/optionAnalyze/getOptionContractDateTime'
import { optionAnalyzeStore } from '~/pages/daddy960_opkevin/component/optionAnalyze/optionAnalyzeStore'
import { useGetOptionContract } from '~/pages/daddy960_opkevin/component/optionAnalyze/useGetOptionContract'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { OptionQuoteTable } from '~/pages/stanli/option/OptionQuoteTable'
import { asia71178_store } from '../asia71178_store'

export const Asia71178_OptionQuote = memo<ReactProps>(function Asia71178_OptionQuote() {
  const state = useSnapshot(optionAnalyzeStore)

  //先設最近交易日結束區間
  const intraday = useSnapshot(staticStore).tradedDate.intraday
  const endDateTime = getOptionIntradayEndDate(intraday)

  // 這邊先一開始先拿選擇權
  /** 契約代號 */
  const defaultContract = useGetOptionContract(endDateTime).default ?? ''
  /** 近期所有契約代號 */
  const contractMonth = useGetOptionContract(endDateTime).contractMonth ?? ['']
  /** 近期契約對應的結算日 */
  const allContractSettlementDate = useGetOptionContract(endDateTime).settlementDate ?? { ['']: '' }
  /** default契約的結算日 */
  const settlementDate = allContractSettlementDate?.[state.currentContract] ?? ''

  useEffect(() => {
    // 首次render將option store基本的資訊set好
    if (defaultContract !== '') {
      optionAnalyzeStore.currentContract = defaultContract
      optionAnalyzeStore.allContractSettlementDate = allContractSettlementDate
      optionAnalyzeStore.allContract = contractMonth
    }
    //defaultContract拿到後,其他資料都會拿到,所以Dependency只放defaultContract
  }, [defaultContract])

  useEffect(() => {
    optionAnalyzeStore.currentSettlementDate = settlementDate
  }, [state.currentContract])

  return (
    <div
      css={css`
        display: grid;
        grid-template-rows: 50px 1fr;
        width: 100%;
        height: 100%;
      `}
    >
      <OptionVolumeSort />
      <OptionQuoteTable />
    </div>
  )
})

const OptionVolumeSort = memo<ReactProps>(function OptionVolumeSort() {
  return (
    <div
      css={css`
        ${fill_horizontal_all_center};
        height: 50px;
      `}
    >
      <div>選擇權排序方式：</div>
      <SegmentedControl
        data={[
          { label: '履約價', value: 'default' },
          { label: '買權成交量', value: 'call' },
          { label: '賣權成交量', value: 'put' },
          { label: '總成交量', value: 'total' },
        ]}
        onChange={val => {
          asia71178_store.optionVolumeSort = val as 'put' | 'default' | 'call' | 'total'
        }}
      />
    </div>
  )
})
